import React, { FC } from "react"
import { Layout } from "layout"
import { PageProps } from "gatsby"
import { FormattedMessage } from "react-intl"

const NotFoundPage: FC<PageProps> = () => {
  return (
    <Layout title="404: Not found">
      <h1>
        <FormattedMessage id="notFound" />
      </h1>
      <p>
        <FormattedMessage id="notFoundMsg" />
      </p>
    </Layout>
  )
}

export default NotFoundPage
